import * as React from 'react';
import { ErrorInfo, Suspense } from 'react';
import { ErrorBoundary, withErrorBoundary } from 'react-error-boundary';
// import { Admin, Resource, ListGuesser, defaultTheme } from 'react-admin';
// import { dataProvider } from './dataProvider';
// import { authProvider } from './authProvider';
// import Layout from './containers/vertical';
// import contacts from './contacts';
// import companies from './companies';
// import deals from './deals';
// import { Dashboard } from './dashboard/Dashboard';
// import { theme } from './theme';
// import GroupOutlined from '@mui/icons-material/GroupOutlined';
// import GroupIcon from '@mui/icons-material/Group';
// import HotelOutlined from '@mui/icons-material/HotelOutlined';
// import HotelIcon from '@mui/icons-material/Hotel';
// import EventAvailableOutlined from '@mui/icons-material/EventAvailableOutlined';
// import EventAvailable from '@mui/icons-material/EventAvailable';
// import ReceiptOutlined from '@mui/icons-material/ReceiptOutlined';
// import ReceiptIcon from '@mui/icons-material/Receipt';
// import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
// import SettingsIcon from '@mui/icons-material/Settings';
// import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
// import DynamicMenuIcon from './components/DynamicMenuIcon';
import { Outlet, Route, Router, Routes } from 'react-router';

//import HotelsApp from 'hp-hotels';
//import AdminApp from 'hp-admin';

import { BrowserRouter } from 'react-router-dom';
import LoadingIndicator from './components/LoadingIndicator';
import SplashScreen from './components/SplashScreen';

const HotelsPaymentApp = React.lazy(() => import('ares-module-payment'));
//const AdminApp = React.lazy(() => import('hp-module-admin'));

const OurFallbackComponent = ({
    error,
    componentStack,
    resetErrorBoundary,
}) => {
    return (
        <div>
            <h1>An error occurred: {error.message}</h1>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    );
};

const App = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={<SplashScreen loading />}>
                <Routes>
                    {/* <Route path="/admin/*" element={<AdminApp />}>
                        <Route index element={<AdminApp />} />
                    </Route>
                    <Route path="hotel_:hotelid/*" element={<HotelsApp />}>
                        <Route index element={<HotelsApp />} />
                        <Route
                            path="hotel_:hotelid/*"
                            element={<HotelsApp />}
                        />
                    </Route> */}
                    <Route path="*" element={<HotelsPaymentApp />}>
                        <Route index element={<HotelsPaymentApp />} />
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
};

export default withErrorBoundary(App, {
    FallbackComponent: OurFallbackComponent,
    onError: (error: Error, info: ErrorInfo) => console.log(info),
}) as any;
