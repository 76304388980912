import * as React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';

const SplashScreen = (props: SplashScreenProps) => {
    const { className, loading, sx, ...rest } = props;

    const theme = useTheme();
    return (
        <Root className={className} sx={sx}>
            {loading ? (
                // <CircularProgress
                //     className={clsx(
                //         'app-loader',
                //         SplashScreenClasses.loader
                //     )}
                //     color="inherit"
                //     size={theme.spacing(2)}
                //     thickness={6}
                //     {...rest}
                // />

                <div id="fuse-splash-screen">
                    <div className="logo">
                        <img
                            width="128"
                            src="assets/images/logo/logo.svg"
                            alt="logo"
                        />
                    </div>
                    <Box
                        id="spinner"
                        sx={{
                            '& > div': {
                                backgroundColor: 'palette.secondary.main',
                            },
                        }}
                    >
                        <div className="bounce1" />
                        <div className="bounce2" />
                        <div className="bounce3" />
                    </Box>
                </div>
            ) : null}
        </Root>
    );
};

export default SplashScreen;

SplashScreen.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    width: PropTypes.string,
    loading: PropTypes.bool,
};

interface SplashScreenProps {
    className?: string;
    sx?: SxProps;
    loading: boolean;
}

const PREFIX = 'HpSplashScreen';

export const SplashScreenClasses = {
    loader: `${PREFIX}-loader`,
    loadedIcon: `${PREFIX}-loadedIcon`,
};

const Root = styled('div', {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    [`& .${SplashScreenClasses.loader}`]: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },

    [`& .${SplashScreenClasses.loadedIcon}`]: {},
}));
